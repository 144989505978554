import styled from "styled-components";
import Text from "../common/components/Text";
import React from "react";
import Heading from "../common/components/Heading";


const PricingWrapper = styled.div`
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  @media (min-width: 993px) {
    padding: 40px 200px;
  }
`;

const PriceTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const PriceTitle = styled.h3`
  color: #d1b106;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const PriceText = styled.span`
  font-size: 18px;
  color: rgb(193, 192, 192);
`;

const PriceValue = styled.span`
  font-size: 18px;
  color: #fdd00c;
  font-weight: bold;
`;

const NoteText = styled(Text)`
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  color: rgb(193, 192, 192);
`;

const PricingSection = () => (
  <PricingWrapper>
    <Heading as="h2" style={{fontSize: '42px', marginBottom: '40px', fontWeight: 800}} content="Lista servizi per cerchi e gomme" />
    <PriceTitle>Raddrizzatura</PriceTitle>
    <PriceTable>
      <PriceRow>
        <PriceText>13/14/15/16”</PriceText>
        <PriceValue>70€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>17”</PriceText>
        <PriceValue>80€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>18”</PriceText>
        <PriceValue>90€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>19”</PriceText>
        <PriceValue>100€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>20”</PriceText>
        <PriceValue>110€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>21”</PriceText>
        <PriceValue>130€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>22”</PriceText>
        <PriceValue>150€</PriceValue>
      </PriceRow>
    </PriceTable>
    
    <PriceTitle>Saldatura Crepe e Bordi</PriceTitle>
    <PriceTable>
      <PriceRow>
        <PriceText>Saldatura Crepe e Bordi</PriceText>
        <PriceValue>20€</PriceValue>
      </PriceRow>
    </PriceTable>
    
    <PriceTitle>Verniciatura</PriceTitle>
    <PriceTable>
      <PriceRow>
        <PriceText>13/14/15/16”</PriceText>
        <PriceValue>80€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>17”</PriceText>
        <PriceValue>100€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>18”</PriceText>
        <PriceValue>110€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>19”</PriceText>
        <PriceValue>120€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>20”</PriceText>
        <PriceValue>140€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>21”</PriceText>
        <PriceValue>160€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>22”</PriceText>
        <PriceValue>190€</PriceValue>
      </PriceRow>
    </PriceTable>

    <PriceTitle>Cambio Colore</PriceTitle>
    <PriceTable>
      <PriceRow>
        <PriceText>fino a 18“</PriceText>
        <PriceValue>+110€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>oltrei 18“</PriceText>
        <PriceValue>+160€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>Colorazione Cromolook chiaraoscura</PriceText>
        <PriceValue>+210€</PriceValue>
      </PriceRow>
    </PriceTable>

    <PriceTitle>Diamantatura</PriceTitle>
    <PriceTable>
      <PriceRow>
        <PriceText>13/14/15”</PriceText>
        <PriceValue>90€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>16”</PriceText>
        <PriceValue>100€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>17”</PriceText>
        <PriceValue>110€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>18”</PriceText>
        <PriceValue>130€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>19”</PriceText>
        <PriceValue>160€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>20”</PriceText>
        <PriceValue>190€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>21”</PriceText>
        <PriceValue>210€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>22”</PriceText>
        <PriceValue>260€</PriceValue>
      </PriceRow>
    </PriceTable>

    <PriceTitle>Sabbiatura</PriceTitle>
    <PriceTable>
      <PriceRow>
        <PriceText>13/14/15/16”</PriceText>
        <PriceValue>110€</PriceValue>
      </PriceRow>
      <PriceRow>
        <PriceText>17” e oltre</PriceText>
        <PriceValue>160€</PriceValue>
      </PriceRow>
    </PriceTable>

    <Text content={'Disponibili anche:'} style={{fontSize: '22px', fontWeight: 800, marginBottom: '20px', marginTop: '40px', color: 'rgb(193, 192, 192)'}} />
    <PriceTable>
      <PriceRow>
        <PriceText>Raddrizzatura Cerchi moto</PriceText>
      </PriceRow>
      <PriceRow>
        <PriceText>Restauro Cerchi auto d’epoca</PriceText>
      </PriceRow>
    </PriceTable>

    <NoteText>*Prezzi intesi per la serie di 4 Cerchi, IVA esclusa e previa visione</NoteText>
  </PricingWrapper>
);

export default PricingSection;
